import React, { useState, useEffect } from "react"
import _ from "lodash"
import styles from "./index.module.scss"
import SearchBar from "../components/search/search-bar/search-bar"
import ConfirmNavLink from "../components/common/confirm-nav-link/confirm-nav-link"
import photoService from "../services/photo"
import storyService from "../services/story"
import StandardCard from "../components/media-output/cards/standard-card/standard-card"
import util from "../services/util"
import classNames from "classnames"
import { FiArrowRight } from "react-icons/fi"
import { MdRoom, MdEmail, MdPhone } from "react-icons/md"
import { FaFacebookF, FaTwitter } from "react-icons/fa"
import PropTypes from "prop-types"
import media_1 from "./home/media_01.jpg"
import media_2 from "./home/media_02.jpg"
import media_3 from "./home/media_03.jpg"
import sponsors_01 from "./home/sponsors_01.svg"
import sponsors_02 from "./home/sponsors_02.svg"
import sponsors_03 from "./home/sponsors_03.svg"
import sponsors_04 from "./home/sponsors_04.svg"
import sponsors_05 from "./home/sponsors_05.svg"
import sponsors_06 from "./home/sponsors_06.svg"
import sponsors_07 from "./home/sponsors_07.svg"
import sponsors_08 from "./home/sponsors_08.svg"
import sponsors_09 from "./home/sponsors_09.svg"
// import SEO from "../services/seo"
import { useStaticQuery, graphql, navigate } from "gatsby"
import WebsiteMenu from "../components/layout/website/website-menu/website-menu"
import auth from "../services/auth"

const AFPAHomePage = ({ siteTitle }) => {
  // const data = useStaticQuery(graphql`
  //   query PageAfpaHomeQuery {
  //     allStrapiCategories {
  //       edges {
  //         node {
  //           categories {
  //             term
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  const searchPath = "/search/"

  const [featuredPhotos, setFeaturedPhotos] = useState([])
  const [featuredStories, setFeaturedStories] = useState([])

  useEffect(() => {
    photoService.findByBranch('afpa').then(
      response => {
        setFeaturedPhotos(response.photos)
      },
      error => {
        console.log("findByBranch photos: ERROR", error)
      }
    )

    storyService.findFeaturedStories('AFPA').then(
      response => {
        setFeaturedStories(response.stories)
      },
      error => {
        console.log("findFeaturedStories stories: ERROR", error)
      }
    )
  }, [])

  const onSearchWord = async word => {
    await navigate(searchPath, { state: { keywords: [word] } })
  }

  const onSearch = async types => {
    await navigate(searchPath, { state: { type: { value: types } } })
  }

  const mediaTypes = [
    {
      title: "Photo",
      description:
        "Download high-quality forestry photos to use in your communications, presentations, articles and more.",
      thumbnail: media_1,
      onClick: () => onSearch(["Photo"]),
    },
    {
      title: "Video",
      description:
        "Browse and download high-quality forestry industry videos for you next presentation or communication.",
      thumbnail: media_2,
      onClick: () => onSearch(["Video"]),
    },
    {
      title: "Documents",
      description:
        "Read forestry industry specific documents covering a wide-range of subjects.",
      thumbnail: media_3,
      onClick: () => onSearch(["Document", "Audio", "Archive"]),
    },
  ]

  // const categoriesObj = 
  //   data.allStrapiCategories.edges.filter(e => !!e && !!e.node).map(e => e.node);
  const projectSponsors = [
    {
      logo: sponsors_01,
      name: "Weyer b&w",
    },
    {
      logo: sponsors_02,
      name: "Westfraser b&w",
    },
    {
      logo: sponsors_03,
      name: "Canfor",
    },
    {
      logo: sponsors_04,
      name: "Mercer",
    },
    {
      logo: sponsors_05,
      name: "Tolko",
    },
    {
      logo: sponsors_06,
      name: "Spray Lake",
    },
    {
      logo: sponsors_07,
      name: "Northland Forest",
    },
    {
      logo: sponsors_08,
      name: "Millar Western",
    },
    {
      logo: sponsors_09,
      name: "Alberta Pacific",
    },
  ]

  return (
    <div className={styles.wrapper}>
      <div className={styles.heroWrap}>
        <header className={styles.header}>
          <div
            className={classNames(
              styles.websiteHeaderLogo,
              "website-header-logo"
            )}
          >
            <ConfirmNavLink to="/">{siteTitle}</ConfirmNavLink>
          </div>
          <WebsiteMenu />
          {auth.isLoggedIn() ? (
            ""
          ) : (
               <ConfirmNavLink to={"/app/login"} >
                 Login
              </ConfirmNavLink>
          )}
        </header>

        <div className={styles.heroContent}>
          <div className={classNames(styles.heroTitle, styles.afpaFont)}>
            Explore Alberta Forest Media
          </div>
          <div className={styles.heroSubtitle}>
            Explore over 10,000 images, videos, documents of Alberta forest
            media.
          </div>
          <SearchBar />
        </div>
      </div>
      <div className={styles.ourMedia}>
        <div className={classNames(styles.headline, styles.afpaFont)}>
          Featured Photos
        </div>
        <div className={styles.images}>
          {_.map(featuredPhotos, (photo) => (
            <div className={styles.type}>
              <img src={photo.thumbnailUrl} />
            </div>
          ))}
        </div>
                <div className={classNames(styles.headline, styles.afpaFont)}>
          Featured Articles
        </div>
        <div className={styles.images}>
          {_.map(featuredStories, (story) => (
            <div className={styles.type}>
              <StandardCard
                key={story._id}
                data={util.fixUrls(story)}
                homePage
              />
            </div>
          ))}
        </div>
        <div className={classNames(styles.headline, styles.afpaFont)}>
          Alberta Forest Media
        </div>
        <p className={classNames(styles.subHeadline, styles.afpaFont)}>
          Over 10,000 high quality stock images and videos shared by our
          talented community.
        </p>
        <div className={styles.category}>
          {mediaTypes.map((i, n) => (
            <button
              key={n}
              type="button"
              className={styles.type}
              onClick={i.onClick}
            >
              <div className={styles.cover}>
                <img src={i.thumbnail} alt={i.title} />
              </div>
              <div className={styles.info}>
                <h4>{i.title}</h4>
                <p>{i.description}</p>
                <span className={styles.link}>
                  <FiArrowRight />
                </span>
              </div>
            </button>
          ))}
        </div>
      </div>
      {/* <div className={styles.keywordWrap}>
        <div className={styles.container}>
          <div className={classNames(styles.headline, styles.afpaFont)}>
            Browse by media category
          </div>
          <ul className={styles.keywords}>
            {categoriesObj[0].categories.map(c => (
              <li key={c.term}>
                <a onClick={() => onSearchWord(c.term)}>{c.term}</a>
              </li>
            ))}
          </ul>
        </div>
      </div> */}

      <div className={styles.projectSponsors}>
        <div className={classNames(styles.headline, styles.afpaFont)}>
          Project Sponsors
        </div>
        <ul className={styles.sponsors}>
          {projectSponsors.map((s, index) => (
            <li key={index}>
              <img src={s.logo} alt={s.name} />
            </li>
          ))}
        </ul>
      </div>

      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.infoWrap}>
            <div className={styles.orgInfo}>
              <div
                className={classNames(
                  styles.websiteFooterLogo,
                  "website-footer-logo"
                )}
              >
                <ConfirmNavLink to="/">{siteTitle}</ConfirmNavLink>
              </div>
              <div className={styles.introduce}>
                <h4>Alberta Forest Products Association</h4>
                <p>
                  Alberta’s forest products industry and the AFPA work together
                  to develop public awareness of the industry, fostering a
                  greater understanding of the economic, environmental, and
                  social values of Alberta’s forests.
                </p>
              </div>
            </div>
            <div className={styles.orgContact}>
              <p>
                <span>
                  <MdRoom />
                </span>
                900 – 10707 100 Avenue, <br /> Edmonton, Alberta, Canada T5J 3M1
              </p>
              <p>
                <span>
                  <MdEmail />
                </span>
                info@afpaassociation.com
              </p>
              <p>
                <span>
                  <MdPhone />
                </span>
                780-452-2841
              </p>
            </div>
          </div>
          <div className={styles.copyrightWrap}>
            <div className={styles.copyright}>
              © 2022 Alberta Forest Products Association. All Rights Reserved.
            </div>
            <div className={styles.socialMedia}>
              <a
                href="https://www.facebook.com/albertaforests/"
                target="_blank"
              >
                <FaFacebookF />
              </a>
              <a href="https://twitter.com/albertaforests" target="_blank">
                <FaTwitter />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

AFPAHomePage.propTypes = {
  siteTitle: PropTypes.string,
}

AFPAHomePage.defaultProps = {
  siteTitle: ``,
}
export default AFPAHomePage
