import React from "react"
import "./search-bar.scss"
import Autocomplete from "../inputs/autocomplete/autocomplete"
import { useForm } from "react-form"
import { navigate } from "gatsby"

const SearchBar = () => {
  const searchPath = "/search/"

  const formInstance = useForm();
  const { Form } = formInstance;

  const onSearch = async word => {
    if (window.location.pathname !== searchPath) {
      await navigate(searchPath, { state: { useComponentId: true, searchMulti: word } })
    }
  }

  const goToSearch = async (event) => {
    // we don't want to submit a form, just do these search actions
    event.preventDefault();
    let searchValue = formInstance.values.searchText;
    if ( searchValue && searchValue.length > 0) {
      await onSearch(searchValue);
    } else {
      await navigate(searchPath);
    }
  }

  return (
    <div className="search-bar-wrapper">
      <Form className="quick-search" onSubmit={goToSearch}>
        {/* <TextField
          type="text"
          placeholder="Search"
          prependIcon="MdSearch"
          field="searchText"
        ></TextField> */}
        {/* <Autocomplete
          index="keyword"
          label=""
          placeholder="Enter keyword to search"
          field="keywords"
          dataField="term"
          clearOnSelect={true}
          onChange={onSearch}
        /> */}
        <Autocomplete
          index="keyword"
          label=""
          placeholder="Search"
          field="multi"
          dataField="term"
          strictSelection={false}
          clearOnSelect={true}
          onChange={onSearch}
        />

        <button type="submit">
          Search
        </button>
      </Form>
    </div>
  )
}

export default SearchBar
